import useJwt from '@/auth/jwt/useJwt';

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = async () => {

    try {

        let isLogged = false;

        const accessToken = localStorage.getItem('accessToken');
        const userData = localStorage.getItem('userData');

        if (accessToken) 
        {
            const response = await useJwt.user();
            if (response.data.success) isLogged = true;
        }

        if (isLogged && userData) isLogged = true;

        if (!isLogged)
        {   
            localStorage.removeItem('userData');
            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
            localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
        }

        return isLogged;

    } catch (err) {

        localStorage.removeItem('userData');
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

        return false;
        
    }

};

export const getUserData = () => JSON.parse(localStorage.getItem('userData'));

export const checkBeforeEnter = (roles, to, from, next) => {

    const userData = getUserData();

    if (roles.includes(userData.role)) next();
        
    else next({ name: 'misc-not-authorized' });

};

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
    if (['superadmin', 'admin'].includes(userRole)) return { name: 'home' }
    return { name: 'login' }
};