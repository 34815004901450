import { checkBeforeEnter } from '@/auth/utils';

export default [
    {
        path: '/propers',
        name: 'propers-list',
        component: () => import('@/views/modules/propers/propers-list/PropersList.vue'),
        beforeEnter: (to, from, next) => { checkBeforeEnter(['superadmin', 'admin'], to, from, next) },
        meta: {
            resource: 'ACL',
            pageTitle: 'Propiedades',
            breadcrumb: [{
                text: 'Lista de propiedades',
                active: true
            }],
            requiresAuth: true
        }
    },
    {
        path: '/propers/create',
        name: 'propers-create',
        component: () => import ('@/views/modules/propers/propers-store/PropersStore.vue'),
        beforeEnter: (to, from, next) => { checkBeforeEnter(['superadmin', 'admin'], to, from, next) },
        meta: {
            resource: 'ACL',
            pageTitle: 'Propiedades',
            breadcrumb: [{
                text: 'Agregar propiedad',
                active: true
            }],
            requiresAuth: true
        }
    },
    {
        path: '/propers/:slug/edit',
        name: 'propers-edit',
        component: () => import ('@/views/modules/propers/propers-edit/PropersEdit.vue'),
        beforeEnter: (to, from, next) => { checkBeforeEnter(['superadmin', 'admin'], to, from, next) },
        meta: {
            resource: 'ACL',
            pageTitle: 'Propiedades',
            breadcrumb: [{
                text: 'Editar propiedad',
                active: true
            }],
            requiresAuth: true
        }
    }
]